<template class="messDiv">
      <div class="messDiv">
        <h1 class="position-absolute start-50 translate-middle">
          Создайте свою первую анкету
        </h1>
          <p class="position-absolute start-50 translate-middle">Готовы начать прямо сейчас ?</p>
          <button type="button" @click="loadCreatePerson" class="btn btn-lg btnBg position-absolute start-50 translate-middle">Начать</button>
      </div>
  </template>
  
  <script>
  import { mapActions, mapState } from 'vuex';
  export default {
    name: 'messReg',
    computed: {
      ...mapState(['currentComponent'])
    },
    methods: {
      ...mapActions(['changeComponent']),
      loadCreatePerson() {
        this.changeComponent('createPerson');
      }
    }
  };
  </script>
  
  <style scoped>
.messDiv{
  width: 100vw;
  /* И по аналогии с высотой, если требуется */
  height: 100vh;
  background: linear-gradient(45deg, #ff7e5f, #feb47b);
  color:white;
}
.messDiv h1 {
  text-align: center;
  margin-top: 50%;
}
.messDiv p {
  text-align: center;
  margin-top: 120%;
}
.messDiv button {
  text-align: center;
  margin-top: 140%;
  color: #feb47b;
  width: 300px;
  background-color:aliceblue;
}
.cover{
  background-color: white;
  width: 300px;
  height: 400px;
}
.colorLi{
  color: #feb47b;
  
}
  </style>
  