<template>
  <div id="app">
    <component :is="currentComponent" />
    <div class="loading" v-if="loading">
  <div class="content">
    <div class="logo"></div>
    <div class="spinner-border text-light lst" role="status"></div>
    <p class="loading-text">Loading...</p>
    <p class="version">v.1.0</p>
  </div>
</div>
    <div v-else>
      <nav class="fixed-bottom navbar-expand-sm bg-light" v-if="actMenu">
        <ul class="nav nav-pills nav-fill gap-2 p-2 small rounded-1 shadow-sm colorss" role="tablist" style="--bs-nav-link-color: #ff7e5f; --bs-nav-pills-link-active-color: #fff; --bs-nav-pills-link-active-bg: #ff7e5f;">
          <li class="nav-item" @click="loadComponent('repost')" role="presentation">
            <i class="bi bi-envelope-open nav-link rounded-5" data-bs-toggle="tab" type="button" role="tab" aria-selected="true" style="font-size: 1.5rem;"></i>
          </li>
          <li class="nav-item" @click="loadComponent('anketsNew')" role="presentation">
            <i class="bi bi-arrow-through-heart-fill nav-link active rounded-5" data-bs-toggle="tab" type="button" role="tab" aria-selected="true" style="font-size: 1.5rem;"></i>
          </li>
          <li class="nav-item" @click="loadComponent('matchAnkets')" role="presentation">
            <i class="bi bi-chat-heart nav-link rounded-5" data-bs-toggle="tab" type="button" role="tab" aria-selected="true" style="font-size: 1.5rem;"></i>
          </li>
          <li class="nav-item" @click="loadComponent('editPerson')" role="presentation">
            <i class="bi bi-person-fill nav-link rounded-5" data-bs-toggle="tab" type="button" role="tab" aria-selected="true" style="font-size: 1.5rem;"></i>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import { postData } from './utils/api';
import { mapActions, mapState } from 'vuex';
import createPerson from './components/createPerson.vue';
import AnketsNew from './components/anketsNew.vue';
import matchAnkets from './components/matchAnkets.vue';
import messReg from './components/messReg.vue';
import sucReg from './components/sucReg.vue';
import noAncets from './components/noAncets.vue';
import repost from './components/repost.vue'
import editPerson from './components/editPerson.vue';
export default {
  name: 'App',
  components:{
    createPerson,
    AnketsNew,
    matchAnkets,
    messReg,
    sucReg,
    noAncets,
    repost,
    editPerson,
  },
  data() {
    return {
      loading: true,
      tg: window.Telegram.WebApp,
    };
  },
  computed: {
    ...mapState(['user', 'anket', 'currentComponent','actMenu']),
  },
  methods: {
    ...mapActions(['updateUserData', 'updateAnketData', 'changeComponent','updateMenuData']),
    async calculateAge(birthdate) {
      const today = new Date();
      const birthDate = new Date(birthdate);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    async loaderInfo() {
      try {
        const data = await postData('/', {
          initData:this.tg.initData,
          initDataUnsafe: this.tg.initDataUnsafe,
          platform: this.tg.platform
        });
        if (data.user) {
          //alert(data.user)
          data.user.token = data.token;
          data.user.birthdate = await this.calculateAge(data.user.birthdate)
          this.updateUserData({...data.user}); 
          this.updateAnketData(data.ankets);
          this.loadComponent('AnketsNew');
          setTimeout(()=>{
            this.loading = false;
          }, 3000); 
        } else {
          this.updateUserData({ token: data.token });
          this.updateMenuData(false)
          this.loadComponent('messReg');
          setTimeout(()=>{
            this.loading = false;
          }, 3000);
        }
      } catch (error) {
        console.error('Ошибка загрузки данных:', error);
      }
    },
    loadComponent(component) {
      this.changeComponent(component);
    },
  },
  mounted() {
    //window.Telegram.WebApp.disableVerticalSwipes() 
  
    //window.Telegram.WebApp.impactOccurred('medium')
    this.loaderInfo();
  },
};
</script>

<style scoped>
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #f7776b, #feb47b);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.content {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50%;
}

.logo {
  width: 300px; /* Задай размер логотипа */
  height: 300px;
  background-image: url('./assets/loader.PNG');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 20px;
}

.spinner-border {
  margin: 20px 0;
}

.loading-text {
  color: white;
  font-size: 1.5em;
  margin-bottom: 10px;
}

.version {
  color: white;
  font-size: 1.2em;
}
.colorss:hover{
  background-color: aliceblue;
}
</style>